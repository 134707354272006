import { create } from "@storybook/theming";

export default create({
  base: "light",

  brandTitle: "Lume Design System",
  brandUrl: "https://lume.mulesoft.com",
  brandImage: "/assets/logo.svg",
  brandTarget: "_self",

  // Typography
  fontBase: "var(--lume-g-font-family)",
  fontCode: "var(--lume-g-font-family-code)",
});
